import { FILES_TYPE_SHORT_NAME } from "constants/attachments";
import { UIErrorMessages } from "Enums";

export const getFileViewType = (file: { type: string | string[]; extension?: string }) => {
    const isTable = ["ODS", "XLS", "XLSX", "TXT"].some(
        (type) => type === FILES_TYPE_SHORT_NAME[file.extension as keyof typeof FILES_TYPE_SHORT_NAME],
    );
    const isDocument =
        !isTable && !file.type.includes("image/") && !file.type.includes("audio/") && !file.type.includes("video/");

    if (isTable) {
        return "TABLE";
    } else if (isDocument) {
        return "DOCUMENT";
    } else if (file.type.includes("image/")) {
        return "IMAGE";
    } else if (file.type.includes("audio/")) {
        return "AUDIO";
    } else if (file.type.includes("video/")) {
        return "VIDEO";
    }

    return null;
};

export const getFileSizeErrorMessage = (size?: string) =>
    `${UIErrorMessages.FILE_SIZE_ERROR}${size ? `: ${size}` : ""}`;
