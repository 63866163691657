import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";
import { TestQuestionType, ProgressStatus, ResourceState, QuizType, Complexity } from "Enums";
import { AccountableUser } from "./UserResponse";

export class TestCategoryResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;
}
export class TestListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => TestCategoryResponse)
    public category: TestCategoryResponse;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Number)
    public questionsCount: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public modifyTime: number;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;
}

export class TestResponseQuestionMaterial {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public materialId: string;
}

export class TestResponseQuestionAnswerPicture {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public contentType: string;

    @Expose()
    @Type(() => Number)
    public size: number;
}

export class TestResponseQuestionAnswer {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => TestResponseQuestionAnswerPicture)
    public picture: TestResponseQuestionAnswerPicture;

    @Expose()
    @Type(() => String)
    public answerText: string;

    @Expose()
    @Type(() => Boolean)
    public isCorrect: boolean;
}

export class TestResponseQuestion {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public questionText: string;

    @Expose()
    @Type(() => String)
    public questionType: TestQuestionType;

    @Expose()
    @Type(() => String)
    public complexity: string;

    @Expose()
    @Type(() => String)
    public answerNote: string;

    @Expose()
    @Type(() => String)
    public correctAnswer: string;

    @Expose()
    @Type(() => TestResponseQuestionMaterial)
    public materialsOnError: TestResponseQuestionMaterial[];

    @Expose()
    @Type(() => TestResponseQuestionAnswer)
    public answers: TestResponseQuestionAnswer[];
}
export class TestResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public categoryId: string;

    @Expose()
    @Type(() => Boolean)
    public isApproxCompletionMinutesManuallyChanged: boolean;

    @Expose()
    @Type(() => String)
    public reviewerId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Boolean)
    public publicAccess: boolean;

    @Expose()
    @Type(() => String)
    public quizType: string;

    @Expose()
    @Type(() => Number)
    public quizTimeLimit: number;

    @Expose()
    @Type(() => Number)
    public questionTimeLimit: number;

    @Expose()
    @Type(() => Boolean)
    public showMistakesDuringQuiz: boolean;

    @Expose()
    @Type(() => Boolean)
    public showMistakesAfterQuiz: boolean;

    @Expose()
    @Type(() => Number)
    public maxAttempts: number;

    @Expose()
    @Type(() => Boolean)
    public notRated: boolean;

    @Expose()
    @Type(() => Number)
    public passPercentage: string;

    @Expose()
    @Type(() => Boolean)
    public shuffledQuestions: boolean;

    @Expose()
    @Type(() => Boolean)
    public shuffledAnswers: boolean;

    @Expose()
    @Type(() => String)
    public successMessage: string;

    @Expose()
    @Type(() => String)
    public failureMessage: string;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;

    @Expose()
    @Type(() => TestResponseQuestion)
    public questions: TestResponseQuestion[];

    @Expose()
    @Type(() => Number)
    public publicationTime: number;

    @Expose()
    @Type(() => Number)
    public archiveTime: number;

    @Expose()
    @Type(() => Number)
    public deadlineTime: number;

    @Expose()
    @Type(() => Number)
    public modifyTime: number;

    @Expose()
    @Type(() => Number)
    public createTime: number;

    @Expose()
    @Type(() => Boolean)
    public hasUserSolutionsOnReview: boolean;

    @Expose()
    @Type(() => Boolean)
    public notIssueCertificateIfFailed: boolean;
}

export class TestUserCountResponse extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public allTab: number;

    @Expose()
    @Type(() => Number)
    public resultsTab: number;
}
export class TestUserListResponseCategory {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;
}
export class TestUserListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => Number)
    public deadlineTime: number | null;

    @Expose()
    @Type(() => TestUserListResponseCategory)
    public category: TestUserListResponseCategory;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Boolean)
    public hasNewLabel: boolean;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public statusChangeTime: number;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;
}

export class TestUser {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;
}
export class TestUserResponseCheckItemMaterial {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public deadlineTimestamp: number;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;
}
export class TestUserResponseCheckItem {
    @Expose()
    @Type(() => TestUser)
    public user: TestUser;

    @Expose()
    @Type(() => Number)
    public checkedTime: number;

    @Expose()
    @Type(() => Boolean)
    public isApproved: boolean;

    @Expose()
    @Type(() => String)
    public questionText: string;

    @Expose()
    @Type(() => String)
    public userAnswer: string;

    @Expose()
    @Type(() => String)
    public comment: string;

    @Expose()
    @Type(() => TestUserResponseCheckItemMaterial)
    public materials: TestUserResponseCheckItemMaterial[];
}
export class TestUserResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => TestUserListResponseCategory)
    public category: TestUserListResponseCategory;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => Number)
    public questionCount: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public availableAttempts: number;

    @Expose()
    @Type(() => Number)
    public quizTimeLimit: number;

    @Expose()
    @Type(() => Number)
    public questionTimeLimit: number;

    @Expose()
    @Type(() => Number)
    public passPercentage: number;

    @Expose()
    @Type(() => String)
    public progressStatus: string;

    @Expose()
    @Type(() => Number)
    public statusChangeTime: number;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => TestUserResponseCheckItem)
    public questionChecks: TestUserResponseCheckItem[];

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;

    @Expose()
    @Type(() => Number)
    public deadlineTime: number;

    @Expose()
    @Type(() => Boolean)
    public affectsCourseCertificate: boolean;
}

export class TestUserStartedResponseQuestionAnswer {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => TestResponseQuestionAnswerPicture)
    public picture: TestResponseQuestionAnswerPicture;

    @Expose()
    @Type(() => String)
    public text: string;

    @Expose()
    @Type(() => Boolean)
    public isSelected: boolean | null;

    @Expose()
    @Type(() => Boolean)
    public isCorrect: boolean | null;

    @Expose()
    @Type(() => Number)
    public lastModified: number;
}

class TestUserStartedResponseQuestionLogo {
    @Expose()
    @Type(() => String)
    public contentType: string;

    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => Number)
    public size: number;
}

export class TestUserStartedResponseQuestion {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => TestUserStartedResponseQuestionLogo)
    public logo: TestUserStartedResponseQuestionLogo;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => String)
    public complexity: string;

    @Expose()
    @Type(() => String)
    public text: string;

    @Expose()
    @Type(() => String)
    public customAnswer: string;

    @Expose()
    @Type(() => String)
    public correctAnswer: string | null;

    @Expose()
    @Type(() => Boolean)
    public approved: boolean | null;

    @Expose()
    @Type(() => Boolean)
    public hasAnswer: boolean;

    @Expose()
    @Type(() => Number)
    public lastModified: number;

    @Expose()
    @Type(() => TestUserStartedResponseQuestionAnswer)
    public answers: TestUserStartedResponseQuestionAnswer[];
}
export class TestUserStartedResponse extends BaseResponse {
    // @Expose()
    // @Type(() => String)
    // public id: string;

    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public quizId: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public currentQuestionId: string;

    @Expose()
    @Type(() => String)
    public quizType: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => Number)
    public quizTimeLimit: number;

    @Expose()
    @Type(() => Number)
    public questionTimeLimit: number;

    @Expose()
    @Type(() => Boolean)
    public showMistakesDuringQuiz: boolean;

    @Expose()
    @Type(() => Boolean)
    public hasNewLabel: boolean;

    @Expose()
    @Type(() => String)
    public progressStatus: string;

    @Expose()
    @Type(() => Number)
    public statusChanged: number | null;

    @Expose()
    @Type(() => Number)
    public attemptNumber: number | null;

    @Expose()
    @Type(() => Number)
    public timeSpent: number | null;

    @Expose()
    @Type(() => Number)
    public lastModified: number;

    @Expose()
    @Type(() => TestUserStartedResponseQuestion)
    public questions: TestUserStartedResponseQuestion[];

    // @Expose()
    // @Type(() => Boolean)
    // public notRated: boolean;
}

export class TestUserValidateResponseAnswer {
    @Expose()
    @Type(() => String)
    public questionId: string;

    @Expose()
    @Type(() => String)
    public answerIds: string[];

    @Expose()
    @Type(() => String)
    public customAnswer: string;
}
export class TestUserValidateResponse extends BaseResponse {
    @Expose()
    @Type(() => Boolean)
    public isCorrect: boolean;

    @Expose()
    @Type(() => TestUserValidateResponseAnswer)
    public correctAnswer: TestUserValidateResponseAnswer;
}

export class TestUserFinishResponseAnswer {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => Boolean)
    public approved: boolean;
}
export class TestUserFinishResponseAnswerWrongItem {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public pictureId: string;

    @Expose()
    @Type(() => String)
    public text: string;

    @Expose()
    @Type(() => Boolean)
    public isSelected: boolean;

    @Expose()
    @Type(() => Boolean)
    public isCorrect: boolean;
}
export class TestUserFinishResponseAnswerWrong {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => String)
    public text: string;

    @Expose()
    @Type(() => String)
    public numericAnswer: string;

    @Expose()
    @Type(() => String)
    public correctNumericAnswer: string;

    @Expose()
    @Type(() => TestUserFinishResponseAnswerWrongItem)
    public answers: TestUserFinishResponseAnswerWrongItem[];
}
export class TestUserFinishResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public timeSpent: number;

    @Expose()
    @Type(() => String)
    public message: string;

    @Expose()
    @Type(() => Number)
    public attemptsAvailable: number;

    @Expose()
    @Type(() => TestUserFinishResponseAnswer)
    public answers: TestUserFinishResponseAnswer[];

    @Expose()
    @Type(() => TestUserFinishResponseAnswerWrong)
    public wrongAnswersInfo: TestUserFinishResponseAnswerWrong[];

    @Expose()
    @Type(() => String)
    public newQuizAttemptId: string;

    @Expose()
    @Type(() => Number)
    public passPercentage: number;
}

class VerificationAccountableUser extends AccountableUser {
    @Expose()
    @Type(() => String)
    public phone: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;
}

export class TestPublicVerificatedResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => VerificationAccountableUser)
    public user: VerificationAccountableUser;

    @Expose()
    @Type(() => Number)
    public statusChangeTime: number;
}

class TestQuestionAnswer {
    @Expose()
    @Type(() => String)
    public questionId: string;

    @Expose()
    @Type(() => String)
    public answerIds: string[];

    @Expose()
    @Type(() => String)
    public customAnswer: string;
}

class TestQuestionLogo {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public contentType: string;

    @Expose()
    @Type(() => Number)
    public size: number;
}

export class TestQuestion {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public type: TestQuestionType;

    @Expose()
    @Type(() => String)
    public text: string;

    @Expose()
    @Type(() => TestQuestionLogo)
    public logo: TestQuestionLogo | null;

    @Expose()
    @Type(() => String)
    public complexity: Complexity;

    @Expose()
    @Type(() => String)
    public customAnswer: string;

    @Expose()
    @Type(() => String)
    public correctAnswer: string;

    @Expose()
    @Type(() => Boolean)
    public approved: boolean;

    @Expose()
    @Type(() => Number)
    public lastModified: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => String)
    public answerNote: string;

    @Expose()
    @Type(() => TestQuestionAnswer)
    public answers: TestQuestionAnswer[];
}

export class TestVerificatedReadResponse {
    @Expose()
    @Type(() => String)
    public progressId: string;

    @Expose()
    @Type(() => String)
    public quizType: QuizType;

    @Expose()
    @Type(() => String)
    public currentQuestionId: string;

    @Expose()
    @Type(() => String)
    public quizId: string;

    @Expose()
    @Type(() => Number)
    public quizTimeLimit: number;

    @Expose()
    @Type(() => Number)
    public questionTimeLimit: number;

    @Expose()
    @Type(() => Boolean)
    public showMistakesDuringQuiz: boolean;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public attemptNumber: number;

    @Expose()
    @Type(() => Number)
    public timeSpent: number;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => Boolean)
    public hasNewLabel: boolean;

    @Expose()
    @Type(() => Number)
    public lastModified: number;

    @Expose()
    @Type(() => Number)
    public statusChanged: number;

    @Expose()
    @Type(() => TestQuestion)
    public questions: TestQuestion[];

    @Expose()
    @Type(() => TestUser)
    public user: TestUser;
}

export class TestReviewSkipResponse {
    @Expose()
    @Type(() => String)
    public nextQuizId: string;
}

class TestStatisticsResponseAttemptQuestionCommentUser {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public email: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public phone: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor: string;
}
class TestStatisticsResponseAttemptQuestionComment {
    @Expose()
    @Type(() => TestStatisticsResponseAttemptQuestionCommentUser)
    public user: TestStatisticsResponseAttemptQuestionCommentUser;

    @Expose()
    @Type(() => String)
    public comment: string;

    @Expose()
    @Type(() => Number)
    public checkedTime: number;
}
class TestStatisticsResponseAttemptQuestionOption {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public pictureId: string;

    @Expose()
    @Type(() => String)
    public answerText: string;

    @Expose()
    @Type(() => Boolean)
    public isUserChecked: boolean;

    @Expose()
    @Type(() => Boolean)
    public isCorrect: boolean;
}
class TestStatisticsResponseAttemptQuestion {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public questionType: string;

    @Expose()
    @Type(() => String)
    public questionText: string;

    @Expose()
    @Type(() => String)
    public numericAnswer: string;

    @Expose()
    @Type(() => String)
    public correctNumericAnswer: string;

    @Expose()
    @Type(() => String)
    public textAnswer: string;

    @Expose()
    @Type(() => TestStatisticsResponseAttemptQuestionOption)
    public optionsAnswers: TestStatisticsResponseAttemptQuestionOption[];

    @Expose()
    @Type(() => Boolean)
    public isApproved: boolean;

    @Expose()
    @Type(() => TestStatisticsResponseAttemptQuestionComment)
    public comment: TestStatisticsResponseAttemptQuestionComment;
}
class TestStatisticsResponseAttempt {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public correctAnswersCount: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public timeSpent: number;

    @Expose()
    @Type(() => Number)
    public finishTime: number;

    @Expose()
    @Type(() => TestStatisticsResponseAttemptQuestion)
    public questions: TestStatisticsResponseAttemptQuestion[];
}
export class TestStatisticsResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public passPercentage: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => Boolean)
    public deadLinePassed: boolean;

    @Expose()
    @Type(() => Number)
    public finishTime: number;

    @Expose()
    @Type(() => Number)
    public timeSpent: number;

    @Expose()
    @Type(() => Number)
    public quizTimeLimit: number;

    @Expose()
    @Type(() => Number)
    public questionTimeLimit: number;

    @Expose()
    @Type(() => Number)
    public attemptsAvailable: number;

    @Expose()
    @Type(() => TestStatisticsResponseAttempt)
    public attempts: TestStatisticsResponseAttempt[];

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;

    @Expose()
    @Type(() => String)
    public onReviewTime: string;
}

export class FilterRatingInfo extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public minQuestionsCount: number;

    @Expose()
    @Type(() => Number)
    public maxQuestionsCount: number;

    @Expose()
    @Type(() => Number)
    public minRatingPoints: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;
}
