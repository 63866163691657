import { Expose, Type } from "class-transformer";
import { MaterialQuestionType, MaterialType } from "Enums";
import { BaseResponse } from "../BaseResponse";
import { FileReadResponse } from "./FileResponse";
import { ScormResponse } from "Api/Responses/ScormResponse";

export class MaterialCategoryResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;
}
export class MaterialListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => MaterialCategoryResponse)
    public category: MaterialCategoryResponse;

    @Expose()
    @Type(() => String)
    public type: MaterialType;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => Number)
    public createTimestamp: number;

    @Expose()
    @Type(() => Number)
    public modifyTimestamp: number;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public managerUserId: string;

    @Expose()
    @Type(() => String)
    public complexity: string;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => MaterialFile)
    public file: MaterialFile;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;
}

export class MaterialQuestionAnswer {
    @Expose()
    @Type(() => String)
    public Id: string;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => Boolean)
    public IsRight: boolean;

    @Expose()
    @Type(() => Number)
    public Position: number;
}

export class MaterialQuestion {
    @Expose()
    @Type(() => String)
    public Id: string;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => Number)
    public Type: MaterialQuestionType;

    @Expose()
    @Type(() => Number)
    public Position: number;

    @Expose()
    @Type(() => MaterialQuestionAnswer)
    public Answers: MaterialQuestionAnswer[];
}

export class MaterialFile {
    @Expose()
    @Type(() => String)
    public contentType: string;

    @Expose()
    @Type(() => String)
    public extension?: string;

    @Expose()
    @Type(() => String)
    public disposableLink: string;

    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => Number)
    public size: number;
}

export class MaterialCategory {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => Number)
    public createTimestamp: number;

    @Expose()
    @Type(() => Number)
    public modifyTimestamp: number;
}

export class VideoPlayback {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public userId: string;

    @Expose()
    @Type(() => String)
    public videoId: string;

    @Expose()
    @Type(() => Number)
    public playbackTimeInSeconds: number;
}

export class MaterialReadResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    // public type: "ARTICLE" | "DOCUMENT" | "TABLE" | "VIDEO" | "SCORM" | "HTML"; //todo define/use enum
    public type: MaterialType;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => Number)
    public createTimestamp: number;

    @Expose()
    @Type(() => Number)
    public modifyTimestamp: number;

    @Expose()
    @Type(() => MaterialCategory)
    public category?: MaterialCategory;

    @Expose()
    @Type(() => String)
    public logoId: string | null;

    @Expose()
    @Type(() => String)
    public managerUserId: string;

    @Expose()
    @Type(() => String)
    public complexity: "EASY" | "MEDIUM" | "HARD" | "SCORM";

    @Expose()
    @Type(() => Number)
    public scoreRewriteLimit: number;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Boolean)
    public hasQuestions: boolean;

    @Expose()
    @Type(() => Boolean)
    public questionsRequired: boolean;

    @Expose()
    @Type(() => Boolean)
    public allowComments: boolean;

    @Expose()
    @Type(() => Boolean)
    public allowRewind: boolean;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;

    @Expose()
    @Type(() => Boolean)
    public isApproxCompletionMinutesManuallyChanged?: boolean;

    @Expose()
    @Type(() => FileReadResponse)
    public attachedFiles: FileReadResponse[];

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public content: string | null;

    @Expose()
    @Type(() => MaterialFile)
    public file: MaterialFile;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => VideoPlayback)
    public playback: VideoPlayback;

    @Expose()
    @Type(() => ScormResponse)
    public scorm: ScormResponse;

    @Expose()
    @Type(() => Boolean)
    public isTraining?: boolean;

    // @Expose()
    // @Type(() => MaterialQuestion)
    // public Questions: MaterialQuestion[];

    // @Expose()
    // @Type(() => MaterialInteractive)
    // public Interactives: MaterialInteractive[];
}

export class MaterialCreateResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public type: "ARTICLE" | "DOCUMENT" | "VIDEO" | "SCORM" | "HTML";

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => Number)
    public createTimestamp: number;

    @Expose()
    @Type(() => Number)
    public modifyTimestamp: number;

    @Expose()
    @Type(() => MaterialCategory)
    public category: MaterialCategory;

    @Expose()
    @Type(() => String)
    public logoId: string | null;

    @Expose()
    @Type(() => String)
    public managerUserId: string;

    @Expose()
    @Type(() => String)
    public complexity: "EASY" | "MEDIUM" | "HARD" | "SCORM";

    @Expose()
    @Type(() => Boolean)
    public hasQuestions: boolean;

    @Expose()
    @Type(() => Boolean)
    public questionsRequired: boolean;

    @Expose()
    @Type(() => Boolean)
    public allowComments: boolean;

    @Expose()
    @Type(() => Boolean)
    public allowRewind: boolean;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;

    @Expose()
    @Type(() => Array)
    public attachedFiles: string[];

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public content: string | null;

    @Expose()
    @Type(() => MaterialFile)
    public file: MaterialFile;

    @Expose()
    @Type(() => ScormResponse)
    public scorm: ScormResponse;
}

export class MaterialFilterRatingResponse {
    @Expose()
    @Type(() => Number)
    public minRatingPoints: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;
}
