/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback, useEffect, useRef, LegacyRef } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Icon, Icons } from "../Icon/Icon";
import { Button, flash } from "../";
import { Loader } from "../Loader/Loader";
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from "constants/attachments";
import { FileReadResponse } from "Api/Responses/FileResponse";
import { GetPreviewResponse } from "Api/Responses/PreviewResponse";
import { humanFileSize } from "helpers/humanFileSize";
import Api from "Api";
import classNames from "classnames";
import AudioPlayer from "Components/AudioPlayer/AudioPlayer";
import { VideoPlayer } from "Components/VideoPlayer";
import { useVideoPlayer } from "hooks/useVideoPlayer";
import { getFileViewType } from "helpers/file";
import { TVoidFunction } from "types";
import { useScreenSize } from "hooks/useMediaQuery";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { fetchFileInfo } from "helpers/fetchFileInfo";
import { WSEventVideoActionTypes, WssMessageType } from "Enums";
import { WssMessage } from "types/WssMessage";

interface IFileViewDialogProps {
    file: FileReadResponse & { type: string; data: string };
    handleClose: TVoidFunction;
    isNoActions?: boolean;
    isNoButtons?: boolean;
    closeButtonClassName?: string;
    onOpen?: (() => void) | ((id: string, isFileProcessedCallback: (isProcessed: boolean) => void) => Promise<void>);
}

interface IImageSize {
    width: string | number;
    height: string | number;
    size: number;
}

interface IVideoPlayerRenderProps {
    fileId: string;
}

const VideoPlayerRender = ({ fileId }: IVideoPlayerRenderProps) => {
    const { getPlayerProps } = useVideoPlayer({
        fluid: true,
        controls: true,
        rewind: true,
        src: `/service/lms-upload/api/video/get-range/${fileId}`,
    });

    return (
        <div className="w-full max-w-175 self-center">
            <VideoPlayer {...getPlayerProps()} />
        </div>
    );
};

const getFilePreview = async (fileId: string) => {
    return await Api.Preview.getFilePreview(fileId);
};

export const FileViewDialog = ({ file: _file, handleClose, closeButtonClassName = "" }: IFileViewDialogProps) => {
    const playerRef = useRef<any>(null);
    const iframeRef = useRef<any>(null);
    const interval = useRef<any>();
    const [loaded, setLoaded] = useState(false);
    const [isImgSrcLoaded, setIsImgSrcLoaded] = useState(false);
    const [isVideoProcessed, setIsVideoProcessed] = useState(false);
    const { size } = useScreenSize();
    const isSmall = size === "small";

    const [imgSizeModifier, setImgSizeModifier] = useState(1);
    const [imgSize, setImgSize] = useState<IImageSize>({ width: "75%", height: "auto", size: 100 });
    const [fileViewType, setFileViewType] = useState<"TABLE" | "DOCUMENT" | "IMAGE" | "AUDIO" | "VIDEO" | null>(null);
    const [isError, setIsError] = useState(false);
    const imgRef = useRef<{ naturalWidth: number; naturalHeight: number } | null>(null);
    const isVideo = fileViewType === "VIDEO";

    const [file, setFile] = useState<GetPreviewResponse | null>(null);
    const [videoFileId, setVideoFileId] = useState<string | null>(null);
    const [preview, setPreview] = useState<GetPreviewResponse | null>(null);

    const onError = () => {
        setIsError(true);
    };

    const fetchDisposableLink = async (fileId: string) => {
        try {
            const previewData = await getFilePreview(fileId);

            setPreview(previewData);
        } catch (error) {
            console.log(error);
        }
    };

    // Скачивание файла - по кнопке "Скачать"
    const saveFile = async (e: any) => {
        e.preventDefault();
        const resp = await Api.File.DownloadFile(_file?.id);

        saveAs(resp, _file?.name);
    };

    const onImageLoaded = (setImgLoaded = false) => {
        const content = document.getElementById("documentContent") as HTMLElement;
        const width = imgRef?.current?.naturalWidth as number;
        const height = imgRef?.current?.naturalHeight as number;
        const offsetWidth = content.offsetWidth;
        const offsetHeight = content?.offsetHeight;

        if (width > height) {
            if (width < offsetWidth - (offsetWidth / 100) * 75) {
                setImgSize({
                    width: width,
                    height: "auto",
                    size: 100,
                });
            } else {
                setImgSize({
                    width: (offsetWidth / 100) * 75,
                    height: "auto",
                    size: 100,
                });
            }
        } else if (height < (offsetHeight / 100) * 75) {
            setImgSize({
                width: "auto",
                height: height,
                size: 100,
            });
        } else {
            setImgSize({
                width: "auto",
                height: (offsetHeight / 100) * 75,
                size: 100,
            });
        }

        if (setImgLoaded) {
            setIsImgSrcLoaded(true);
        }
    };

    // Приближение изображения
    const increaseImg = () => {
        if (imgSizeModifier >= 2) {
            return;
        }

        setImgSizeModifier((prevState) => prevState + 0.25);
        setImgSize((prevState) => ({ ...prevState, size: prevState.size + 25 }));
    };

    // Отдаление изображения
    const decreaseImg = () => {
        if (imgSizeModifier <= 0.5) {
            return;
        }

        setImgSizeModifier((prevState) => prevState - 0.25);
        setImgSize((prevState) => ({ ...prevState, size: prevState.size - 25 }));
    };

    const clearCheckingInterval = () => {
        clearInterval(interval.current);
    };

    const onIframeLoaded = useCallback(() => {
        clearCheckingInterval();
    }, []);

    useEffect(() => {
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            const { messageType } = message;
            if (messageType === WssMessageType.NOTIFICATION) {
                const { actionType, objectId } = message.body;
                if (actionType === WSEventVideoActionTypes.PROCESSING_COMPLETE) {
                    setVideoFileId(objectId as string);
                    setIsVideoProcessed(true);
                    setLoaded(true);
                }
            }
            if (
                messageType === WssMessageType.FILE_PREVIEW_READY &&
                [preview?.fileList, preview?.preview].every((item) => !item)
            ) {
                const previewData = await getFilePreview(_file.id);

                setPreview(previewData);
            }
            if (messageType === WssMessageType.FILE_PREVIEW_FAILED) {
                flash.error("Произошла ошибка при обработке файла");
                handleClose();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (fileViewType === "DOCUMENT") {
            const body = document.querySelector("body");
            if (body) {
                body.style.overflow = "hidden";
            }

            return () => {
                if (body) {
                    body.style.overflow = "auto";
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileViewType]);

    useEffect(() => {
        if (fileViewType === "VIDEO") {
            if (_file?.id && _file?.disposableLink) {
                setLoaded(false);
                setVideoFileId(_file?.id);
                fetchFileInfo(_file?.id, setIsVideoProcessed)?.then(() => {
                    setLoaded(true);
                });
            } else {
                setIsVideoProcessed(false);
                setLoaded(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_file, fileViewType]);

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();

        const getPreview = async () => {
            if (fileViewType === "IMAGE") {
                // blob
                const resp = await Api.File.DownloadFile(_file.id);
                const fileReader = new FileReader();
                fileReader.readAsDataURL(resp);
                fileReader.onloadend = () => {
                    setFile({
                        fileId: _file?.id,
                        status: "SUCCESS",
                        contentType: "image/jpeg",
                        preview: fileReader.result as string,
                    });
                };
            } else if (fileViewType === "AUDIO") {
                if (playerRef.current) {
                    playerRef.current.load();
                }
            }
        };

        getPreview().then();

        return () => cancelTokenSource.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileViewType, _file]);

    useEffect(() => {
        if (preview?.preview) {
            setLoaded(true);
        }
        if (preview?.preview && fileViewType === "TABLE") {
            iframeRef.current.contentWindow.document.writeln(preview?.preview);
        }
    }, [preview, fileViewType]);

    useEffect(() => {
        if (!fileViewType) {
            setFileViewType(getFileViewType({ type: _file?.type, extension: _file?.extension }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_file?.type]);

    useEffect(() => {
        if (!_file?.id) {
            return;
        }

        if (!["DOCUMENT", "TABLE"].includes(String(fileViewType))) {
            return;
        }

        fetchDisposableLink(_file.id).then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_file, fileViewType]);

    return (
        <div className="fixed top-0 left-0 flex flex-col justify-between w-[100vw] h-[100vh] overflow-auto z-[101]">
            <div className="fixed top-0 right-0 bottom-0 left-0 bg-black opacity-40 z-[1]"></div>
            {/* Header */}
            <div className="fixed top-0 right-0 left-0 flex items-center px-4 h-16 bg-background-light print:hidden z-[3]">
                {/* fileWrapper */}
                <div className="flex items-center w-full">
                    <div className="pr-4">
                        <Icon
                            icon={FILES_TYPE_ICONS[_file?.extension as string] || Icons.FileEdit}
                            className="!fill-white"
                            width={32}
                            height={32}
                        />
                    </div>
                    <div className="flex flex-col">
                        <div className="text-black p1-accent line-clamp-1">{_file.name}</div>
                        <div className="text-black p1 opacity-50">
                            <span className="uppercase">
                                {_file?.type &&
                                    FILES_TYPE_SHORT_NAME[_file?.extension as keyof typeof FILES_TYPE_SHORT_NAME]}
                            </span>
                            {" — "}
                            {(!isVideo || (isVideo && isVideoProcessed)) && <>{humanFileSize(_file.size, true)}</>}
                            {isVideo && !isVideoProcessed && "Видео обрабатывается..."}
                        </div>
                    </div>
                </div>
                {/* mobile */}
                <Button
                    className="flex sm:hidden gap-1.5 !p-0 !w-11 !h-8 !rounded-md flex-shrink-0"
                    onClick={saveFile}
                    disabled={(isVideo && !isVideoProcessed) || (fileViewType !== "IMAGE" && !loaded)}
                >
                    <Icon
                        icon={Icons.Download}
                        className={`fill-white ${isVideo && !isVideoProcessed ? "!fill-gray-light" : ""}`}
                        width={20}
                        height={20}
                    />
                </Button>
                {/* desktop */}
                <Button
                    className="hidden sm:flex gap-1.5 !px-4"
                    onClick={saveFile}
                    disabled={(isVideo && !isVideoProcessed) || (fileViewType !== "IMAGE" && !loaded)}
                >
                    <Icon
                        icon={Icons.Download}
                        className={`fill-white ${isVideo && !isVideoProcessed ? "!fill-gray-light" : ""}`}
                        width={20}
                        height={20}
                    />
                    <span className="hidden sm:flex">Скачать</span>
                </Button>
                <div className="flex flex-shrink-0 sm:hidden ml-3" onClick={handleClose}>
                    <Icon icon={Icons.Close} width={32} height={32} className="!fill-blue-drk" />
                </div>
            </div>
            {/* Body */}
            <div className="grow relative top-0 w-full h-[calc(100vh - 64px)] pt-16 pb-14 z-[2]">
                {!isSmall && (
                    <Button
                        shape="square"
                        color="common"
                        variant="outline"
                        icon={<Icon icon={Icons.Close} width={22} height={22} />}
                        iconPlacement={"center"}
                        className={`border-[#EAEDF3] !text-base !font-medium fixed top-[76px] right-3 rounded-r-none 2xl:rounded-r-none z-[10] ${closeButtonClassName}`}
                        onClick={handleClose}
                    />
                )}
                <div id="documentContent" className="flex justify-center pt-8 pb-12 w-full h-full">
                    {!isError ? (
                        <>
                            {fileViewType === "IMAGE" && (
                                <>
                                    {file?.status === "SUCCESS" ? (
                                        <img
                                            ref={imgRef as LegacyRef<HTMLImageElement>}
                                            alt=""
                                            onLoad={() => {
                                                onImageLoaded(true);
                                            }}
                                            onError={onError}
                                            src={file?.preview}
                                            className={`m-auto opacity-${isImgSrcLoaded ? "100" : "0"}`}
                                            style={{
                                                width: isNaN(Number(imgSize.width))
                                                    ? imgSize.width
                                                    : Number(imgSize.width) * imgSizeModifier + "px",
                                                height: isNaN(Number(imgSize.height))
                                                    ? imgSize.height
                                                    : Number(imgSize.height) * imgSizeModifier + "px",
                                            }}
                                        />
                                    ) : (
                                        <div className="m-auto">
                                            <Loader />
                                        </div>
                                    )}
                                </>
                            )}
                            {fileViewType === "AUDIO" && (
                                <div className="flex items-center w-full max-w-200">
                                    <AudioPlayer
                                        ref={playerRef}
                                        src={`/service/lms-upload/api/video/get-range/${_file?.id}`}
                                    />
                                </div>
                            )}
                            {isVideo && (
                                <>
                                    {loaded ? (
                                        <>
                                            {isVideoProcessed && videoFileId ? (
                                                <VideoPlayerRender fileId={videoFileId} />
                                            ) : (
                                                <div className="flex-center flex-col max-w-[1000px] w-full max-h-[562px] bg-gray-dark rounded-lg grow-1 my-auto h-full">
                                                    <Icon
                                                        icon={Icons.Loader}
                                                        width={36}
                                                        height={36}
                                                        className="!fill-gray-light"
                                                    />
                                                    <p className="text-white text-2xl mt-4">Видео обрабатывается...</p>
                                                    <p className="text-white p1 mt-2">
                                                        Пожалуйста, дождитесь завершения обработки видео
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="m-auto">
                                            <Loader />
                                        </div>
                                    )}
                                </>
                            )}
                            {["DOCUMENT", "TABLE"].includes(String(fileViewType)) ? (
                                <div className="absolute inset-0 pt-16">
                                    {!loaded ? (
                                        <div className="absolute inset-0 flex items-center justify-center">
                                            <Loader />
                                        </div>
                                    ) : (
                                        <>
                                            {_file.type === "application/zip" && (
                                                <div className="m-auto absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 text-center flex flex-col items-center">
                                                    <Icon
                                                        icon={Icons.Ban}
                                                        width={36}
                                                        height={36}
                                                        className="!fill-white"
                                                    />
                                                    <p className="text-white text-2xl mt-4">
                                                        Предпросмотр файла недоступен
                                                    </p>
                                                    <p className="text-white p1 mt-2">
                                                        Данный формат не поддерживается
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {_file.type !== "application/zip" && (
                                        <>
                                            {fileViewType === "DOCUMENT" && (
                                                <div className="flex flex-col items-center gap-4 pb-20">
                                                    {preview?.fileList?.map((img, idx) => {
                                                        return (
                                                            <img
                                                                key={idx}
                                                                ref={
                                                                    idx === 0
                                                                        ? (imgRef as LegacyRef<HTMLImageElement>)
                                                                        : null
                                                                }
                                                                alt=""
                                                                onLoad={() => {
                                                                    if (idx === 0) {
                                                                        onImageLoaded();
                                                                    } else {
                                                                        onIframeLoaded();
                                                                    }
                                                                    setLoaded(true);
                                                                }}
                                                                src={`${img}`}
                                                                style={{
                                                                    width: isNaN(Number(imgSize.width))
                                                                        ? imgSize.width
                                                                        : Number(imgSize.width) * imgSizeModifier +
                                                                          "px",
                                                                    height: isNaN(Number(imgSize.height))
                                                                        ? imgSize.height
                                                                        : Number(imgSize.height) * imgSizeModifier +
                                                                          "px",
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            )}
                                            {fileViewType === "TABLE" && (
                                                <iframe
                                                    ref={iframeRef}
                                                    className={`w-full h-full ${loaded ? "bg-white" : ""}`}
                                                    style={{}}
                                                    onLoad={onIframeLoaded}
                                                    onError={(e) => {
                                                        console.log("FileViewDialog iframe onError", e);
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <div className="flex-center flex-col">
                            <Icon icon={Icons.Ban} className="!fill-red" width={"42px"} height="42px" />
                            <div className="pt-4 pb-4 font-medium text-white">Предпросмотр файла недоступен</div>
                        </div>
                    )}
                </div>
            </div>
            {/* Footer */}
            {["IMAGE", "DOCUMENT"].includes(String(fileViewType)) && (
                <div className="fixed left-0 right-0 bottom-0 flex items-center justify-center h-16 bg-[rgba(19,37,50,0.3)] z-[3]">
                    <Button
                        color="common"
                        className={classNames(
                            "w-10 !rounded-full focus:!ring-0 active:!ring-0 flex justify-center flex-wrap !p-0 text-[rgba(255,255,255,0.5)] bg-transparent border-1 border-solid border-white whitespace-nowrap 2xl:w-12.5",
                            { "cursor-not-allowed border border-solid border-[#7B8F9C] ": imgSize?.size <= 50 },
                        )}
                        onClick={decreaseImg}
                    >
                        <Icon
                            icon={Icons.Minus}
                            className={classNames("!fill-white", { "!fill-gray-text": imgSize?.size <= 50 })}
                            width={24}
                            height={24}
                        />
                    </Button>
                    <div className="mx-4 w-[4ch] text-white p1 text-center">{imgSize?.size}%</div>
                    <Button
                        color="common"
                        className={classNames(
                            "w-10 !rounded-full focus:!ring-0 active:!ring-0 flex justify-center flex-wrap !p-0 text-[rgba(255,255,255,0.5)] bg-transparent border-1 border-solid border-white whitespace-nowrap 2xl:w-12.5",
                            { "cursor-not-allowed border border-solid border-[#7B8F9C] ": imgSize?.size >= 200 },
                        )}
                        onClick={increaseImg}
                    >
                        <Icon
                            icon={Icons.Plus}
                            className={classNames("!fill-white", { "!fill-gray-text": imgSize?.size >= 200 })}
                            width={24}
                            height={24}
                        />
                    </Button>
                </div>
            )}
        </div>
    );
};
