import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { Icons, Icon, FileUpload, Tooltip, flash, Section, Loader } from "Uikit";
import Api from "Api";
import { MaterialFile } from "Api/Responses/MaterialResponse";
import { GetPreviewResponse } from "Api/Responses/PreviewResponse";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { useUpload } from "hooks/useUpload";
import { FileUploadType, UIErrorMessages, WssMessageType } from "Enums";
import { WssMessage } from "types/WssMessage";

interface MaterialDocumentProps {
    attachment?: MaterialFile;
    onChange: (field: string, value: any) => void;
}

const getFilePreview = async (fileId: string, successCallback: (res: GetPreviewResponse) => void) => {
    const res = await Api.Preview.getFilePreview(fileId);

    if (res.status === "SUCCESS") {
        successCallback(res);
    }
};

export const MaterialDocument = ({ attachment, onChange }: MaterialDocumentProps) => {
    const uploadRef = useRef<HTMLInputElement>(null);
    const iframeRef = useRef<any>(null);
    const interval = useRef<any>();

    const { upload } = useUpload();
    const [file, setFile] = useState<any>();
    const [loaded, setLoaded] = useState(false);
    const [preview, setPreview] = useState<GetPreviewResponse | null>(null);

    const hostname = window.location.hostname.includes("localhost")
        ? "http://localhost:3000"
        : window.location.protocol + "//" + window.location.hostname;

    function onDrop(file: any) {
        if (!file) {
            return;
        }

        setLoaded(false); // когда новая загрузка
        setPreview(null); // когда новая загрузка
        onChange?.("fileId", null);
        onChange?.("file", null);

        const onUpdate = async (file: any) => setFile(file);
        const onFinished = async (file: any) => {
            onChange?.("fileId", file.serverData.id);
            onChange?.("file", file.serverData);
            onChange?.("fileType", String(file.serverData.extension).toUpperCase());
            setFile(null);
        };

        const extensionIdx = file.name.lastIndexOf(".");
        file["extension"] = file.name.slice(extensionIdx + 1);

        const u = upload({
            file: file,
            onUpdate,
            onFinished,
            fileUploadType: FileUploadType.MATERIAL_DOCUMENT,
        });

        // u.start()
        // .then();
        const start = u.start();
        start.catch((error) => {
            const knownError = error as BadRequestResponse;
            if (
                [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                    String(knownError.errorCode) as ErrorCode,
                )
            ) {
                flash.error(UIErrorMessages.FILE_LOADING_ERROR);
            }
            setFile(null);
            return;
        });

        setFile(u);
    }

    const clearCheckingInterval = () => {
        clearInterval(interval.current);
    };

    const onFileContentLoaded = useCallback(() => {
        clearCheckingInterval();
        setLoaded(true);
    }, []);

    const fetchDisposableLink = async (fileId: string) => {
        await getFilePreview(fileId, (result: GetPreviewResponse) => {
            setPreview(result);
        });
    };

    const onUploadClick = () => {
        if (uploadRef.current) {
            uploadRef.current.click();
        }
    };

    function cancelUpload() {
        file?.cancel();
        setFile(attachment);
    }

    useEffect(() => {
        if (!attachment?.id || attachment.contentType.includes("image")) {
            return;
        }

        fetchDisposableLink(attachment.id).then();
    }, [attachment]);

    useEffect(() => {
        if (preview && !loaded) {
            setLoaded(true);
            iframeRef.current?.contentWindow.document.write(preview?.preview);
        }
    }, [preview, loaded]);

    useEffect(() => {
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            const { messageType } = message;
            if (messageType === WssMessageType.FILE_PREVIEW_READY) {
                await getFilePreview(String(message.body.fileId), (result: GetPreviewResponse) => {
                    setPreview(result);
                });
            }
            if (messageType === WssMessageType.FILE_PREVIEW_FAILED) {
                flash.error("Произошла ошибка при обработке файла");
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Section label="Документ" isRequired className="h-full">
            {attachment ? (
                <div className="w-full max-w-200 relative" id="adminNewMaterialDocumentContent">
                    <div
                        className={clsx(
                            "flex gap-4 justify-center items-center w-full bg-gray-blue shadow-lg rounded-lg overflow-hidden h-160",
                            loaded && "!overflow-auto",
                            preview?.fileList && "flex-wrap",
                        )}
                    >
                        {preview?.preview && (
                            <iframe
                                ref={iframeRef}
                                className={`w-full h-160 ${loaded ? "bg-white" : ""}`}
                                onLoad={onFileContentLoaded}
                                onError={() => {}}
                            />
                        )}
                        {preview?.fileList && (
                            <>
                                {preview?.fileList?.map((img, idx) => {
                                    return (
                                        <img
                                            key={idx}
                                            alt=""
                                            src={`${img}`}
                                            onLoad={
                                                idx === 0
                                                    ? () => {
                                                          onFileContentLoaded();
                                                      }
                                                    : undefined
                                            }
                                        />
                                    );
                                })}
                            </>
                        )}
                        {attachment.contentType.includes("image") && (
                            <img
                                className="rounded-lg object-cover max-w-175 p-6"
                                src={"/service/lms-upload/api/file/download/" + attachment.id}
                                onLoad={onFileContentLoaded}
                            />
                        )}

                        {!loaded && (
                            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                                <Loader />
                            </div>
                        )}
                    </div>
                    <div className="absolute z-10 flex top-2.5 right-6 space-x-1">
                        <Tooltip content="Скачать">
                            <a
                                href={hostname + "/service/lms-upload/api/file/download/" + attachment.id}
                                download={attachment.name}
                                className="flex items-center justify-center w-11 h-11 rounded-lg bg-black cursor-pointer"
                                id="adminNewMaterialDocumentLinkDownload"
                            >
                                <Icon icon={Icons.Download} width="20px" height="20px" color="fill-white" />
                            </a>
                        </Tooltip>
                        <Tooltip content="Загрузить">
                            <div
                                className="flex items-center justify-center w-11 h-11 rounded-lg bg-black cursor-pointer"
                                onClick={onUploadClick}
                                id="adminNewMaterialDocumentBtnDownload"
                            >
                                <Icon icon={Icons.Upload} width="20px" height="20px" color="fill-white" />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            ) : null}

            <FileUpload
                ref={uploadRef}
                className={clsx(attachment && "hidden")}
                onChange={onDrop}
                onCancelUpload={cancelUpload}
                attachment={file}
                accept={{
                    "application/msword": [],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
                    "application/pdf": [],
                    "application/vnd.ms-powerpoint": [],
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
                    "application/vnd.openxmlformats-officedocument.presentationml.slideshow": [],
                    "application/vnd.ms-excel": [],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
                    "text/plain": [],
                    "image/jpeg": [],
                    "image/png": [],
                }}
                maxSize={{
                    "text/*": 104857600,
                    "application/*": 104857600,
                    "image/*": 2097152,
                }}
                acceptDescription="pdf, txt, doc, docx, xls, xlsx, ppt, pps, pptx, ppsx до 100 МБ или изображение до 2 МБ"
            />
        </Section>
    );
};
